export const data = [
  "moltres",
  "bulbasaur",
  "entei",
  "ivysaur",
  "zapdos",
  "venusaur",
  "charmander",
  "charmeleon",
  "charizard",
  "articuno",
  "ho-oh",
  "squirtle",
  "wartortle",
  "blastoise",
  "caterpie",
  "metapod",
  "suicune",
  "butterfree",
  "lugia",
  "weedle",
  "kakuna",
  "beedrill",
  "pidgey",
  "pidgeotto",
  "pidgeot",
  "rattata",
  "raticate",
  "raikou",
];
